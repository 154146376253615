<template>
    <li
        class="transition rounded-md hover:bg-gray-100"
        :class="{
            'bg-gray-100 text-red-400 font-semibold': $route.name === routeName,
            'text-gray-700': $route.name !== routeName,
        }"
    >
        <router-link
            :to="{ name: routeName, params: { uuid: clientUuid, ...extraRouteParams }}"
            class="flex p-2"
        >
            <fa-icon :icon="icon" fixed-width class="mr-2" />
            <div class="flex flex-1 items-center justify-between">
                <p class="text-xs">
                    {{ label }}
                </p>
                <div v-if="badge !== null" class="bg-gray-200 text-sm text-white px-2 rounded-md ">
                    {{ badge }}
                </div>
            </div>
        </router-link>
    </li>
</template>
<script>
export default {
    props: {
        routeName: {
            type:    String,
            default: '',
        },
        label: {
            type:    String,
            default: '',
        },
        icon: {
            type:    Array,
            default: () => [],
        },
        badge: {
            type:    Number,
            default: null,
        },
        extraRouteParams: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },
};
</script>
